<template>
  <b-card title="Toko Sister List">
    <b-col cols="12 row text-left" class="p-0 mt-1 mb-1">
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal-ffl-participant
        >
          Export Female Future Leader Participant
        </b-button>
      </b-col>
    </b-col>
    <b-row class="mb-3 justify-content-between">
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name / Email / Phone Number / Title"
            v-model="filter.search"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="timeline">Timeline:</label>
          <v-select
            id="timeline"
            label="title"
            v-model="filter.timeline_uuid"
            :options="timelineList"
            placeholder="-- Pilih --"
            :reduce="(timelineList) => timelineList.uuid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="form-group">
          <label for="timeline">Status:</label>
          <v-select
            id="timeline"
            label="name"
            v-model="filter.status"
            :options="status"
            placeholder="-- Pilih --"
            :reduce="(status) => status.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :participant="participant"
      :is-loading="isLoading"
      :get-data="getData"
      :chooseSelected="chooseSelected"
      :current-page="currentPage"
    />
    <Modal
      :get-history="getParticipantHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :filterParams="filter"
    />
  </b-card>
</template>

<script>
import Table from "@/components/female-future-leader-participant/Table.vue";
import Modal from "@/components/female-future-leader-participant/Modal.vue";
import vSelect from "vue-select";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    Modal,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      participant: {},
      timelineList: [],
      link: "",
      filter: {},
      status: [
        { value: "registration-draft", name: "Registration Draft" },
        { value: "registration-submitted", name: "Registration Submitted" },
        { value: "registration-failed", name: "Registration Failed" },
        { value: "registration-passed", name: "Registration Passed" },
        { value: "e-learning-assignment-submitted", name: "E-Learning Assignment Submitted" },
        { value: "e-learning-assignment-failed", name: "E-Learning Assignment Failed" },
        { value: "e-learning-assignment-passed", name: "E-Learning Assignment Passed" },
        { value: "english-and-gmat-test-submitted", name: "English and GMAT Submitted" },
        { value: "english-and-gmat-test-failed", name: "English and GMAT Failed" },
        { value: "english-and-gmat-test-passed", name: "English and GMAT Passed" },
        { value: "leaderless-group-discussion-submitted", name: "Leaderless Group Discussion Submitted" },
        { value: "leaderless-group-discussion-failed", name: "Leaderless Group Discussion Failed" },
        { value: "leaderless-group-discussion-passed", name: "Leaderless Group Discussion Passed" },
        { value: "online-interview-submitted", name: "Online Interview Submitted" },
        { value: "online-interview-failed", name: "Online Interview Failed" },
        // { value: "online-interview-passed", name: "Online Interview Passed" },
        { value: "complete", name: "Complete" },
      ],
      histories: [],
      columns: [],
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.loadTimeline();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      this.filter.page = page;
      this.$http
        .get("/api/v1/admin/female-future-leaders/participant", {
          params: this.filter,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.participant = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadTimeline() {
      this.$http.get("/api/v1/admin/ffl-timelines").then((response) => {
        this.timelineList = response.data.data.items;
      });
    },
    getParticipantHistory() {
      this.$http
        .get("/api/v1/admin/export/history/female-future-leader")
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=female-future-leader")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chooseSelected(slug, name) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure about this decision?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          if (name === "selected") {
            this.formSpotlight.is_selected = "true";
          } else {
            this.formSpotlight.is_favorite = "true";
          }
          this.$http
            .post(
              `/api/v1/admin/sister-store/${slug}/set-spotlight`,
              this.formSpotlight
            )
            .then((response) => {
              this.getData(this.currentPage);
              this.formSpotlight = {};
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Toko Sister berhasil di update!",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
