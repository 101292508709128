<template>
  <div>
    <b-table
      striped
      hover
      :items="participantList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template
        v-slot:cell(user_personal_information.information.photo)="{ item }"
      >
        <img
          :src="item.user_personal_information.information.photo"
          @error="
            $event.target.src = require(`@/assets/images/avatars/no-image.png`)
          "
          class="rounded media_file"
          alt=""
        />
      </template>

      <template v-slot:cell(counting)="{ item }">
        <ul class="pl-1">
          <li>{{ item.counting.like }} Likes</li>
          <li>{{ item.counting.comment }} Comments</li>
        </ul>
      </template>

      <template v-slot:cell(user_personal_information)="{ item }">
        <ul class="pl-1" v-if="item.user_personal_information && item.user_personal_information.information">
          <li>
            Name: <b>{{ item.user_personal_information.information.name }}</b>
          </li>
          <li>
            Email: <b>{{ item.user_personal_information.information.email }}</b>
          </li>
          <li>
            Phone Number: <b>{{ item.user_personal_information.information.phone_number }}</b>
          </li>
          <li>
            Birth Date: <b>{{ item.user_personal_information.information.birth_date }}</b>
          </li>
        </ul>
        <ul class="pl-1" v-else>
          <li>
            Name: <b>-</b>
          </li>
          <li>
            Email: <b>-</b>
          </li>
          <li>
            Phone Number: <b>-</b>
          </li>
          <li>
            Birth Date: <b>-</b>
          </li>
        </ul>
      </template>

      <template v-slot:cell(timeline)="{ item }">
        <ul class="pl-1">
          <li>
            Title: <b>{{ item.timeline.title }}</b>
          </li>
          <li>
            Registration Date: <br />
            <b
              >{{ item.timeline.date_range.registration_start_date }} to
              {{ item.timeline.date_range.registration_end_date }}
            </b>
          </li>
          <li>
            Selection Date: <br />
            <b
              >{{ item.timeline.date_range.selection_start_date }} to
              {{ item.timeline.date_range.selection_end_date }}
            </b>
          </li>
          <li>
            Announcement Date: <br />
            <b
              >{{ item.timeline.date_range.announcement_start_date }} to
              {{ item.timeline.date_range.announcement_end_date }}
            </b>
          </li>
        </ul>
      </template>

      <template v-slot:cell(status)="{ item }">
        <div class="text-capitalize">
          {{ item.status.replaceAll("-", " ").toLowerCase() }}
        </div>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item
            :to="'/detail-female-future-leader-participant/' + item.uuid"
          >
            <feather-icon icon="EditIcon" class="mr-75" />
            Detail
          </b-dropdown-item>
          <b-dropdown-item
            @click="chooseSelected(item.uuid, 'selected')"
            v-if="item.status === 'approved'"
          >
            <feather-icon icon="AwardIcon" class="mr-75" />
            Pilih Toko Terpilih
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    participant: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    chooseSelected: {
      type: Function,
    },
    // chooseFavorite: {
    //   type: Function,
    // },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    participant(value) {
      this.participantList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
  },
  data() {
    return {
      moment,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      participantList: [],
      fields: [
        { key: "no" },
        { key: "user_personal_information", label: "User" },
        { key: "timeline", label: "Timeline" },
        { key: "status" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.participantList.length;
    },
  },
};
</script>

<style>
.media_file {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 0.357rem !important;
}
</style>
